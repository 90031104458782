import classnames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Components, ItemContent, Virtuoso } from 'react-virtuoso';

import type { Board } from '@smartfolly/frontend.boards-service';

import { Button, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import { BoardTemplates } from '@smartfolly/sdk';
import { isDesktop, isMobile } from 'react-device-detect';
import { assetsService, authService, boardsService } from '../../services';

import { BoardCard } from './BoardCard';

import { Tooltip } from '../Common';
import styles from './Sidebar.module.scss';
import { Footer } from './SidebarFooter';
import { Header } from './SidebarHeader';

const cnb = classnames.bind(styles);

const sidebarGridStyles = { height: 'calc(100vh - 65px)' };

const tooltipConfig = {
    offset: [12, 6] as [number, number],
};

export const Sidebar = observer(function Sidebar() {
    const { session } = authService;
    const { featuredBoards, boards } = boardsService;
    const { assets } = assetsService;

    const navigate = useNavigate();
    const { pathname } = useLocation();

    // States
    const [show, setShow] = useState<boolean>(
        isDesktop ? JSON.parse(localStorage.getItem('showSidebar') ?? 'true') : false,
    );

    useEffect(() => {
        if (isDesktop) {
            localStorage.setItem('showSidebar', JSON.stringify(show));
        }
        document.body.classList.add(show ? 'sidebar-open' : 'sidebar-close');
    }, [show]);

    // Getters

    const isAssetsEmpty = useMemo(() => assets.length === 0, [assets]);

    const allBoards = useMemo(() => featuredBoards.concat(boards), [featuredBoards, boards]);

    const allBoardsAssetsLength = useMemo(
        () => allBoards.reduce((acc, board) => acc + board.selectedAssets.length, 0),
        [allBoards],
    );

    const listComponents: Components<Board, unknown> = useMemo(
        () => ({
            Header: () => <Header disabled={allBoardsAssetsLength === 0} />,
            Footer: () => <Footer />,
        }),
        [allBoardsAssetsLength],
    );

    const monitorRightIcon = useMemo<IButtonIcon>(
        () => ({
            icon: pathname.startsWith('/overview') ? (
                <Icon icon="monitor-on" />
            ) : (
                <Icon icon="monitor-off" />
            ),
        }),
        [pathname],
    );

    const toggleLeftIcon = useMemo<IButtonIcon>(
        () => ({
            icon: show ? <Icon icon="sidebar-on" /> : <Icon icon="sidebar-off" />,
        }),
        [show],
    );

    // Actions

    const handleClose = useCallback(() => {
        setShow(false);
        document.body.classList.remove('sidebar-open');
    }, []);

    const handleShow = useCallback(() => {
        setShow(true);
        document.body.classList.add('sidebar-open');
    }, []);

    const navigateTo = useCallback((url: string) => navigate(url), [navigate]);

    const toggleMonitor = useCallback(() => {
        if (isMobile) {
            handleClose();
        }
        navigate(pathname.startsWith('/overview') ? '/boards/total' : '/overview');
    }, [navigate, handleClose, pathname]);

    const clickCallback = useCallback(
        (board: Board) => {
            if (isMobile) {
                handleClose();
            }
            if (board.template?.kind === BoardTemplates.Total) {
                navigateTo('/boards/total'); // as we must have only one .Total board kind
            } else {
                navigateTo(`/boards/${board.boardId}`);
            }
        },
        [navigateTo, handleClose],
    );

    // Render

    const renderBoardCard: ItemContent<Board, unknown> = useCallback(
        (_index, board) => {
            const boardPathname =
                board.template?.kind === BoardTemplates.Total
                    ? '/boards/total'
                    : `/boards/${board.boardId}`;
            return (
                <BoardCard
                    board={board}
                    clickCallback={clickCallback}
                    isActive={pathname === boardPathname}
                />
            );
        },
        [clickCallback, pathname],
    );

    const overviewBtnClassName = useMemo(
        () =>
            `${
                pathname.startsWith('/overview') ? cnb('monitor-on-btn') : cnb('monitor-off-btn')
            } onboarding-step-1`,
        [pathname],
    );

    const overviewBtnTitle = useMemo(() => {
        return pathname.startsWith('/overview') ? 'Close monitor' : 'Monitor funds';
    }, [pathname]);

    if (session === null) {
        return null;
    }

    return (
        <>
            {show ? (
                <Tooltip content="Hide boards" config={tooltipConfig}>
                    <Button
                        className={cnb('open-link')}
                        onClick={handleClose}
                        aria-label="Hide boards"
                        iconLeft={toggleLeftIcon}
                    />
                </Tooltip>
            ) : (
                <Tooltip content="Show boards" config={tooltipConfig}>
                    <Button
                        className={cnb('open-link')}
                        onClick={handleShow}
                        aria-label="Show boards"
                        iconLeft={toggleLeftIcon}
                    />
                </Tooltip>
            )}
            <div className={`app-sidebar ${show ? 'show' : ''}`}>
                <Tooltip content={overviewBtnTitle}>
                    <div className={cnb('monitor-btn-wrapper')}>
                        <Button
                            onClick={toggleMonitor}
                            className={overviewBtnClassName}
                            aria-label={overviewBtnTitle}
                            iconRight={monitorRightIcon}
                            disabled={isAssetsEmpty}
                        >
                            Overview
                        </Button>
                    </div>
                </Tooltip>
                <Virtuoso
                    className={`${cnb('sidebar-cards-grid')} onboarding-step-2 m-l-0.5`}
                    style={sidebarGridStyles}
                    data={allBoards}
                    itemContent={renderBoardCard}
                    components={listComponents}
                />
            </div>
        </>
    );
});
