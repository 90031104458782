import BigNumber from 'bignumber.js';

import type {
    ExchangeCurrency,
    ProvidedAddressWithWallets,
    ProvidedExchangeWithWallets,
} from '@smartfolly/frontend.assets-service';
import { priceToString } from '@smartfolly/frontend.currencies-service';

/**
 * Hook to get the total price string value out of the provided wallet group.
 * @param providedWallet - a provided wallet group to get the total price value from.
 * @param currency - a currency to get the total price in.
 * @returns the total price string out of the provided wallet group.
 */
export const useProvidedWalletTotalPriceString = function useProvidedWalletTotalBalance(
    { wallets }: ProvidedAddressWithWallets | ProvidedExchangeWithWallets,
    currency: ExchangeCurrency,
): string {
    const totalBalanceValue = wallets.reduce<BigNumber>((acc, wallet) => {
        return acc.plus(wallet.totalPrice.value ?? new BigNumber(0));
    }, new BigNumber(0));

    return priceToString(totalBalanceValue, currency);
};
