import classnames from 'classnames/bind';
import { useCallback, useState } from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';

import { Flex, FlexContainer, Icon } from '@smartfolly/frontend.web-ui';

import { CustomBoardModal } from '../Board';

import { pirschClient } from '../../services/Pirsch';
import styles from './Sidebar.module.scss';

const cnb = classnames.bind(styles);

export function Header({ disabled }: { disabled: boolean }) {
    // States

    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

    // Actions

    const onCreateModalShow = useCallback(() => {
        pirschClient.event('Started building board');
        setShowCreateModal(true);
    }, []);

    const onCreateModalHide = useCallback(() => setShowCreateModal(false), []);

    return (
        <>
            <FlexContainer>
                <Flex className="title title-normal m-l-1 m-b-0.5 m-t-1.25">Boards</Flex>
            </FlexContainer>
            <FlexContainer direction="column" justify="space-between" align="stretch">
                <Flex className={cnb('footer')}>
                    <FlexContainer direction="row" justify="space-between" align="stretch">
                        <Flex grow={1} className="p-b-1 p-t-1">
                            <BootstrapButton
                                className="w-100 p-t-1 p-b-1 b-r-3 p-l-1 text-left disabled:back-secondary"
                                onClick={onCreateModalShow}
                                aria-label="Create new board"
                                disabled={disabled}
                            >
                                <span className="action action-normal m-t-1">Create new board</span>
                                <Icon icon="add-plus" className="align-top float-end" />
                            </BootstrapButton>
                        </Flex>
                    </FlexContainer>
                </Flex>
                {showCreateModal && <CustomBoardModal hideModal={onCreateModalHide} />}
            </FlexContainer>
        </>
    );
}
