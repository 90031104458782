import * as amplitude from '@amplitude/analytics-browser';
import classNames from 'classnames/bind';
import { useState, useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';

import { CustomToggle, Icon } from '@smartfolly/frontend.web-ui';

import { getQuickActionsLinks } from '@smartfolly/frontend.assets-service';

import styles from './Sidebar.module.scss';
import { Tooltip } from '../Common';

const quickLinksIcons = {
    buy: 'add-plus-mini',
    swap: 'h-change-mini',
    bridge: 'bridge-mini',
    stake: 'percentage-mini',
    sell: 'card-mini',
};

const cnb = classNames.bind(styles);

const quickLinksTooltopConfig = {
    placement: 'bottom' as const,
    offset: [0, 5] as [number, number],
};
export function Footer() {
    // States
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    // Getters

    const quickActions = getQuickActionsLinks();

    // Actions

    const buy = useCallback(() => {
        amplitude.logEvent('Buy clicked');
        window.open(quickActions.buy, '_blank');
    }, [quickActions]);
    const swap = useCallback(() => {
        amplitude.logEvent('Swap clicked');
        window.open(quickActions.swap, '_blank');
    }, [quickActions]);
    const bridge = useCallback(() => {
        amplitude.logEvent('Bridge clicked');
        window.open(quickActions.bridge, '_blank');
    }, [quickActions]);
    const stake = useCallback(() => {
        amplitude.logEvent('Stake clicked');
        window.open(quickActions.stake, '_blank');
    }, [quickActions]);
    const sell = useCallback(() => {
        amplitude.logEvent('Sell clicked');
        window.open(quickActions.sell, '_blank');
    }, [quickActions]);

    const dropdownToggle = useCallback(
        (status: boolean) => {
            setMenuOpen(status);
        },
        [setMenuOpen],
    );

    return (
        <Dropdown className={cnb('quick-actions-dropdown')} drop="up" onToggle={dropdownToggle}>
            <Tooltip content="Drop-down list" config={quickLinksTooltopConfig}>
                <Dropdown.Toggle
                    as={CustomToggle}
                    backdrop
                    icon=""
                    variant="transparent"
                    className="onboarding-step-6"
                >
                    <span
                        className={`action action-special b-r-3 back-primary p-t-0.5 p-b-0.5 p-l-0.5 p-r-0.5 ${
                            menuOpen ? 'color-text-secondary' : 'color-text-accent'
                        } hover:back-tertiary ${cnb('toggle-button')}`}
                    >
                        {menuOpen ? <Icon icon="x-close-delete-mini" /> : <Icon icon="fast-mini" />}
                        <span className="action action-special">Quick actions</span>
                    </span>
                </Dropdown.Toggle>
            </Tooltip>
            <Dropdown.Menu className="custom-dropdown-menu">
                <Dropdown.Item className="action action-special" onClick={buy}>
                    {quickActions.buy && (
                        <span>
                            <Icon icon={quickLinksIcons.buy} className="m-r-0.25" />
                            <span className="action action-special color-text-accent">Buy</span>
                        </span>
                    )}
                </Dropdown.Item>
                <Dropdown.Item className="action action-special" onClick={swap}>
                    {quickActions.swap && (
                        <span>
                            <Icon icon={quickLinksIcons.swap} className="m-r-0.25" />
                            <span className="action action-special color-text-accent">Swap</span>
                        </span>
                    )}
                </Dropdown.Item>
                <Dropdown.Item className="action action-special" onClick={bridge}>
                    {quickActions.bridge && (
                        <span>
                            <Icon icon={quickLinksIcons.bridge} className="m-r-0.25" />
                            <span className="action action-special color-text-accent">Bridge</span>
                        </span>
                    )}
                </Dropdown.Item>
                <Dropdown.Item className="action action-special" onClick={stake}>
                    {quickActions.stake && (
                        <span>
                            <Icon icon={quickLinksIcons.stake} className="m-r-0.25" />
                            <span className="action action-special color-text-accent">Stake</span>
                        </span>
                    )}
                </Dropdown.Item>
                <Dropdown.Item className="action action-special" onClick={sell}>
                    {quickActions.sell && (
                        <span>
                            <Icon icon={quickLinksIcons.sell} className="m-r-0.25" />
                            <span className="action action-special color-text-accent">Sell</span>
                        </span>
                    )}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}
