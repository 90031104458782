import { observer } from 'mobx-react';
import { useState, useCallback, useMemo } from 'react';
import { Components, GroupContent, GroupItemContent, GroupedVirtuoso } from 'react-virtuoso';

import { Log } from '@smartfolly/common.utilities';

import { Flex } from '@smartfolly/frontend.web-ui';

import type {
    ProvidedAddressWithWallets,
    ProvidedExchangeWithWallets,
} from '@smartfolly/frontend.assets-service';

import { assetsService } from '../../../services';

import { GroupHeader } from '../../Common';

import { EditWalletCell } from './EditWalletCell';

type ProvidedWallet = ProvidedAddressWithWallets | ProvidedExchangeWithWallets;

const listComponents: Components<ProvidedWallet> = {
    TopItemList: GroupHeader,
};

const log = new Log('EditWallets');

export const EditWallets = observer(function EditWalletsModal() {
    // Stores

    const { providedAddressesWithWallets: addresses, providedExchangesWithWallets: exchanges } =
        assetsService;

    // States

    const [totalHeight, setTotalHeight] = useState<number>(0);

    // Getters

    // Calculate the provided wallets list
    const providedWallets = useMemo<ProvidedWallet[]>(() => {
        // Form the list of all provided wallets to display
        return (addresses as ProvidedWallet[]).concat(exchanges);
    }, [addresses, exchanges]);

    // Need to prepare the groupCounts property for the GroupedVirtuoso
    // Note: has only two groups
    const groupCounts = useMemo(() => {
        return exchanges.length > 0 ? [addresses.length, exchanges.length] : [addresses.length];
    }, [addresses.length, exchanges.length]);

    // Actions

    const handleTotalListHeightChanged = useCallback((h: number) => setTotalHeight(h), []);

    // Render

    const listStyle = useMemo(
        () => ({
            height: totalHeight,
            maxHeight: 'calc(100vh - 260px)',
            minHeight: '80px',
        }),
        [totalHeight],
    );

    const renderSectionHeader: GroupContent = useCallback(index => {
        if (index === 0) {
            return (
                <Flex className="p-t-0.5 p-b-0.5 title title-small">
                    Select an address with associated wallets
                </Flex>
            );
        }

        if (index === 1) {
            return <Flex className="p-t-0.5 p-b-0.5 title title-small">or exchange</Flex>;
        }

        // Display nothing otherwise
        log.warning('The edit section header is not rendered with index:', index);
        return null;
    }, []);

    const renderEditWallet: GroupItemContent<ProvidedWallet, unknown> = useCallback(
        index => {
            const item = providedWallets[index]!;
            return <EditWalletCell item={item} />;
        },
        [providedWallets],
    );

    return (
        <GroupedVirtuoso
            className="scrollable"
            style={listStyle}
            groupCounts={groupCounts}
            groupContent={renderSectionHeader}
            itemContent={renderEditWallet}
            components={listComponents}
            totalListHeightChanged={handleTotalListHeightChanged}
        />
    );
});
