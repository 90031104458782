import classNames from 'classnames/bind';
import { observer } from 'mobx-react';
import { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Log } from '@smartfolly/common.utilities';
import { Button, IButtonIcon, Icon } from '@smartfolly/frontend.web-ui';

import { useNumberToWords } from '../../hooks';
import { assetsService, boardsService, pirschClient } from '../../services';
import { showToast } from '../../utils';

import styles from './Board.module.scss';

const log = new Log('AddDynamicBoard');

const cnb = classNames.bind(styles);

const iconLeft: IButtonIcon = {
    icon: <Icon icon="add-plus" className="m-r-0" />,
    animation: 'none',
};

export const AddDynamicBoard = observer(function AddDynamicBoard() {
    const { appliedFilters } = assetsService;
    const { addBoard, boards } = boardsService;

    // Hooks
    const navigate = useNavigate();

    // Getters
    const showSaveButton = useMemo(() => {
        return Object.keys(appliedFilters).length > 0;
    }, [appliedFilters]);

    const newBoardName = `New board ${useNumberToWords(boards.length + 1)}`;

    // Actions

    const saveDynamicBoard = useCallback(async () => {
        try {
            const result = await addBoard({ name: newBoardName, filters: appliedFilters });
            if (!result) {
                throw new Error('Board was not added via "addBoard" method');
            }

            pirschClient.event('Created dynamic board');
            showToast('Board saved');
            navigate(`/boards/${result.boardId}#rename`);
        } catch (error) {
            log.error('Failed to add the board with error:', error);

            showToast('Something went wrong');
        }
    }, [addBoard, appliedFilters, newBoardName, navigate]);

    return (
        <Button
            className={`back-special-accent-dark hover:back-special-accent-light hover:color-text-bw action action-special m-b-2 ${cnb(
                'add-dynamic-board-btn',
                showSaveButton ? 'show' : 'hide',
            )}`}
            iconLeft={iconLeft}
            onClick={saveDynamicBoard}
        >
            <span className="m-l-0.25 m-r-0.25">Save as board</span>
        </Button>
    );
});
